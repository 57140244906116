import { ReactElement, ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ResetScrollOnPathChange({
  children,
}: {
  children: ReactNode;
}): ReactElement {
  const { pathname } = useLocation();

   
  useEffect(() => window.scrollTo({ top: 0 }), [pathname]);

  return <>{children}</>;
}
