import { ReactElement } from "react";
import { Navigate } from "react-router-dom";

import { AnyPathPart, AnyQueryParams } from "./base";
import { DeriveIncomingQueryProps, DerivePathProps } from "./derive";
import { Route } from "./Route";

export interface RedirectProps<
  P extends AnyPathPart[],
  Q extends AnyQueryParams,
> {
  path: DerivePathProps<P>;
  query: DeriveIncomingQueryProps<Q>;
  route: Route<P, Q>;
}

export function Redirect<P extends AnyPathPart[], Q extends AnyQueryParams>(
  props: RedirectProps<P, Q>
): ReactElement {
  const { path, query, route } = props;

  const url = route.url(path, query);

  console.debug("Redirect", route.reactRouterPattern(), "=>", url);

  return <Navigate to={url} />;
}
