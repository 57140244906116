import { Workspace, workspaceDisabledRole } from "@cartographerio/types";
import { filterAndMap } from "@cartographerio/util";
import { VStack } from "@chakra-ui/react";
import { sortBy, take } from "lodash-es";
import { ReactElement, useEffect, useMemo } from "react";
import { IoEllipsisHorizontal } from "react-icons/io5";

import queries from "../../../queries";
import { useApiParams, useCredentials } from "../../contexts/auth";
import { useRecentWorkspacesState } from "../../hooks/useRecentWorkspaces";
import { useSuspenseSearchResults } from "../../hooks/useSuspenseSearchResults";
import { routes } from "../../routes";
import Avatar from "../Avatar";
import Spaced from "../Spaced";
import WorkspaceAvatar from "../WorkspaceAvatar";

const NUM_WORKSPACES_ON_SWITCHER = 9;

export interface WorkspaceSwitcherProps {
  admin?: boolean | null;
  workspace: Workspace | null;
}

export default function WorkspaceSwitcher(
  props: WorkspaceSwitcherProps
): ReactElement {
  const { admin: isAdmin, workspace: currentWorkspace } = props;

  const apiParams = useApiParams();
  const {
    identity: { userId, roles },
  } = useCredentials();

  const workspaces = useSuspenseSearchResults(
    queries.workspace.v2.registered(apiParams, userId)
  );

  const [recentWorkspaces, setRecentWorkspaces] = useRecentWorkspacesState();

  useEffect(() => {
    if (currentWorkspace != null) {
      setRecentWorkspaces(recentWorkspaces => [
        currentWorkspace.id,
        ...recentWorkspaces.filter(
          workspace => workspace !== currentWorkspace.id
        ),
      ]);
    }
  }, [currentWorkspace, setRecentWorkspaces]);

  const disabledWorkspaces = useMemo(
    () =>
      new Set(
        filterAndMap(workspaces, ({ id }) =>
          roles.includes(workspaceDisabledRole(id)) ? id : null
        )
      ),
    [roles, workspaces]
  );

  const sorted = useMemo(
    () =>
      take(
        sortBy(workspaces, ws =>
          recentWorkspaces.includes(ws.id)
            ? recentWorkspaces.indexOf(ws.id)
            : recentWorkspaces.length
        ),
        NUM_WORKSPACES_ON_SWITCHER
      ),
    [workspaces, recentWorkspaces]
  );

  const showEllipsis = workspaces.length > NUM_WORKSPACES_ON_SWITCHER;

  return useMemo(
    () => (
      <VStack justifyContent="space-between" h="100%" py="4" overflow="hidden">
        <Spaced spacing="2" px="2">
          {sorted.map(workspace => (
            <WorkspaceAvatar
              key={workspace.id}
              workspaceRef={workspace.alias}
              name={workspace.name}
              logo={workspace.logo ?? undefined}
              active={isAdmin !== true && workspace.id === currentWorkspace?.id}
              disabled={disabledWorkspaces.has(workspace.id)}
            />
          ))}
          {showEllipsis && (
            <Avatar
              to={routes.home.url([])}
              name="Show more..."
              initials="..."
              icon={<IoEllipsisHorizontal size="1.5rem" />}
              active={false}
            />
          )}
        </Spaced>
      </VStack>
    ),
    [currentWorkspace?.id, disabledWorkspaces, isAdmin, showEllipsis, sorted]
  );
}
