import { envvar } from "./util/envvar";

const mode = envvar("MODE");
export const isDevelopmentMode = mode === "development";
export const isProductionMode = mode === "production";

export const fathomSiteId: string = envvar("VITE_FATHOM_SITE_ID");

export const gpsPrecision: number = 6;
export const ngrPrecision: number = 5;
