import { checks } from "@cartographerio/permission";
import {
  PermissionCheck,
  WorkspaceId,
  WorkspaceRef,
} from "@cartographerio/types";
import { checkExhausted } from "@cartographerio/util";
import { FunctionComponent, ReactElement, lazy } from "react";
import { Navigate, RouteObject, useRoutes } from "react-router-dom";

import queries from "../../queries";
import { Redirect, RouteProps } from "../../routes";
import { AnyPathPart, AnyQueryParams } from "../../routes/base";
import { DeriveOutgoingQueryProps, DerivePathProps } from "../../routes/derive";
import { RedirectProps } from "../../routes/Redirect";
import { Route } from "../../routes/Route";
import CommandBarProvider from "../components/CommandBarProvider";
import ForbidSignin from "../components/ForbidSignin";
import PageLayout from "../components/PageLayout";
import RequirePermission from "../components/RequirePermission";
import RequireSignin from "../components/RequireSignin";
import { useApiParams } from "../contexts/auth";
import { useSuspenseQueryData } from "../hooks/useSuspenseQueryData";
import * as path from "./pathParams";
import * as routes from "./routes";

const AccountPage = lazy(() => import("../pages/AccountPage"));
const AdminBillingListPage = lazy(
  () => import("../pages/admin/AdminBillingListPage")
);
const AdminHomePage = lazy(() => import("../pages/admin/AdminHomePage"));
const AdminInvitationCodeCreatePage = lazy(
  () => import("../pages/admin/AdminInvitationCodeCreatePage")
);
const AdminInvitationCodeListPage = lazy(
  () => import("../pages/admin/AdminInvitationCodeListPage")
);
const AdminInvitationCodeViewPage = lazy(
  () => import("../pages/admin/AdminInvitationCodeViewPage")
);
const AdminInvitationCreatePage = lazy(
  () => import("../pages/admin/AdminInvitationCreatePage")
);
const AdminInvitationListPage = lazy(
  () => import("../pages/admin/AdminInvitationListPage")
);
const AdminInvitationViewPage = lazy(
  () => import("../pages/admin/AdminInvitationViewPage")
);
const AdminMemberListPage = lazy(
  () => import("../pages/admin/AdminMemberListPage")
);
const AdminRcaSetupPage = lazy(
  () => import("../pages/admin/AdminRcaSetupPage")
);
const AdminUserCreatePage = lazy(
  () => import("../pages/admin/AdminUserCreatePage")
);
const AdminUserTransferPage = lazy(
  () => import("../pages/admin/AdminUserTransferPage")
);
const AdminUserUpdatePage = lazy(
  () => import("../pages/admin/AdminUserUpdatePage")
);
const AdminWorkspaceCreatePage = lazy(
  () => import("../pages/admin/AdminWorkspaceCreatePage")
);
const AdminWorkspaceListPage = lazy(
  () => import("../pages/admin/AdminWorkspaceListPage")
);
const AttachmentFolderPage = lazy(
  () => import("../pages/attachment/AttachmentFolderPage")
);
const AttachmentPage = lazy(() => import("../pages/attachment/AttachmentPage"));
const ChangeIdentityPage = lazy(() => import("../pages/ChangeIdentityPage"));
const EmbedAttachmentFolderPage = lazy(
  () => import("../pages/embed/EmbedAttachmentFolderPage")
);
const EmbedAttachmentPage = lazy(
  () => import("../pages/embed/EmbedAttachmentPage")
);
const EmbedMapPage = lazy(() => import("../pages/embed/EmbedMapPage"));
const ForgotPasswordPage = lazy(() => import("../pages/ForgotPasswordPage"));
const FrontPluginPage = lazy(() => import("../pages/FrontPluginPage"));
const HomePage = lazy(() => import("../pages/HomePage"));
const InvitationAcceptPage = lazy(
  () => import("../pages/InvitationAcceptPage")
);
const MapboxStyleViewerPage = lazy(
  () => import("../pages/MapboxStyleViewerPage")
);
const ProjectHomePage = lazy(() => import("../pages/project/ProjectHomePage"));
const ProjectMapPage = lazy(() => import("../pages/project/ProjectMapPage"));
const ProjectSurveyCreatePage = lazy(
  () => import("../pages/project/ProjectSurveyCreatePage")
);
const ProjectSurveyUpdatePage = lazy(
  () => import("../pages/project/ProjectSurveyUpdatePage")
);
const ArcgisIntegrationAuthorizePage = lazy(
  () => import("../pages/project/settings/ArcgisIntegrationAuthorize")
);
const ArcgisIntegrationCreatePage = lazy(
  () => import("../pages/project/settings/ArcgisIntegrationCreatePage")
);
const ArcgisIntegrationListPage = lazy(
  () => import("../pages/project/settings/ArcgisIntegrationListPage")
);
const ArcgisIntegrationUpdatePage = lazy(
  () => import("../pages/project/settings/ArcgisIntegrationPage")
);
const ProjectIntegrationsPage = lazy(
  () => import("../pages/project/settings/ProjectIntegrationsPage")
);
const ProjectInvitationCodeListPage = lazy(
  () => import("../pages/project/settings/ProjectInvitationCodeListPage")
);
const ProjectInvitationCodeViewPage = lazy(
  () => import("../pages/project/settings/ProjectInvitationCodeViewPage")
);
const ProjectInvitationListPage = lazy(
  () => import("../pages/project/settings/ProjectInvitationListPage")
);
const ProjectInvitationViewPage = lazy(
  () => import("../pages/project/settings/ProjectInvitationViewPage")
);
const ProjectMemberListPage = lazy(
  () => import("../pages/project/settings/ProjectMemberListPage")
);
const ProjectSettingsPage = lazy(
  () => import("../pages/project/settings/ProjectSettingsPage")
);
const ProjectSurveyTransferPage = lazy(
  () => import("../pages/project/settings/ProjectSurveyTransferPage")
);
const ProjectTeamListPage = lazy(
  () => import("../pages/project/settings/ProjectTeamListPage")
);
const ProjectTransferPage = lazy(
  () => import("../pages/project/settings/ProjectTransferPage")
);
const ProjectUserUpdatePage = lazy(
  () => import("../pages/project/settings/ProjectUserPage")
);
const ProjectSurveyListPage = lazy(
  () => import("../pages/project/SurveyListPage")
);
const QualificationCertificatePage = lazy(
  () => import("../pages/qualification/QualificationCertificatePage")
);
const QualificationRegisterPage = lazy(
  () => import("../pages/qualification/QualificationRegisterPage")
);
const ResetPasswordPage = lazy(() => import("../pages/ResetPasswordPage"));
const RestoreIdentityPage = lazy(() => import("../pages/RestoreIdentityPage"));
const InvitationCodeSignupApprovePage = lazy(
  () => import("../pages/short/InvitationCodeSignupApprovePage")
);
const InvitationCodeSignupRejectPage = lazy(
  () => import("../pages/short/InvitationCodeSignupRejectPage")
);
const ShortInvitationCodePage = lazy(
  () => import("../pages/short/ShortInvitationCodeUrlPage")
);
const ShortInvitationPage = lazy(
  () => import("../pages/short/ShortInvitationUrlPage")
);
const ShortMapUrlPage = lazy(() => import("../pages/short/ShortMapUrlPage"));
const ShortProjectUrlPage = lazy(
  () => import("../pages/short/ShortProjectUrlPage")
);
const ShortSurveyUrlPage = lazy(
  () => import("../pages/short/ShortSurveyUrlPage")
);
const ShortTeamUrlPage = lazy(() => import("../pages/short/ShortTeamUrlPage"));
const ShortWorkspacePage = lazy(
  () => import("../pages/short/ShortWorkspacePage")
);
const ShortWorkspaceUrlPage = lazy(
  () => import("../pages/short/ShortWorkspaceUrlPage")
);
const SigninPage = lazy(() => import("../pages/signin/SigninPage"));
const SigninWithCodePage = lazy(
  () => import("../pages/signin/SigninWithCodePage")
);
const SignupPage = lazy(() => import("../pages/signin/SignupPage"));
const SignoutPage = lazy(() => import("../pages/SignoutPage"));
const TeamHomePage = lazy(() => import("../pages/team/TeamHomePage"));
const TeamInvitationCodeListPage = lazy(
  () => import("../pages/team/TeamInvitationCodeListPage")
);
const TeamInvitationCodeViewPage = lazy(
  () => import("../pages/team/TeamInvitationCodeViewPage")
);
const TeamInvitationListPage = lazy(
  () => import("../pages/team/TeamInvitationListPage")
);
const TeamInvitationViewPage = lazy(
  () => import("../pages/team/TeamInvitationViewPage")
);
const TeamMemberListPage = lazy(
  () => import("../pages/team/TeamMemberListPage")
);
const TeamSettingsPage = lazy(() => import("../pages/team/TeamSettingsPage"));
const TeamUserPage = lazy(() => import("../pages/team/TeamUserPage"));
const WorkspaceBillingDashboardPage = lazy(
  () => import("../pages/workspace/WorkspaceBillingDashboardPage")
);
const WorkspaceBillingPortalPage = lazy(
  () => import("../pages/workspace/WorkspaceBillingPortalPage")
);
const WorkspaceBillingSettingsPage = lazy(
  () => import("../pages/workspace/WorkspaceBillingSettingsPage")
);
const WorkspaceHoldingPage = lazy(
  () => import("../pages/workspace/WorkspaceHoldingPage")
);
const WorkspaceHomePage = lazy(
  () => import("../pages/workspace/WorkspaceHomePage")
);
const WorkspaceInvitationCodeCreatePage = lazy(
  () => import("../pages/workspace/WorkspaceInvitationCodeCreatePage")
);
const WorkspaceInvitationCodeListPage = lazy(
  () => import("../pages/workspace/WorkspaceInvitationCodeListPage")
);
const WorkspaceInvitationCodeViewPage = lazy(
  () => import("../pages/workspace/WorkspaceInvitationCodeViewPage")
);
const WorkspaceInvitationCreatePage = lazy(
  () => import("../pages/workspace/WorkspaceInvitationCreatePage")
);
const WorkspaceInvitationListPage = lazy(
  () => import("../pages/workspace/WorkspaceInvitationListPage")
);
const WorkspaceInvitationViewPage = lazy(
  () => import("../pages/workspace/WorkspaceInvitationViewPage")
);
const WorkspaceMemberListPage = lazy(
  () => import("../pages/workspace/WorkspaceMemberListPage")
);
const WorkspaceProjectCreatePage = lazy(
  () => import("../pages/workspace/WorkspaceProjectCreatePage")
);
const WorkspaceProjectListPage = lazy(
  () => import("../pages/workspace/WorkspaceProjectListPage")
);
const WorkspaceSettingsPage = lazy(
  () => import("../pages/workspace/WorkspaceSettingsPage")
);
const WorkspaceTeamCreatePage = lazy(
  () => import("../pages/workspace/WorkspaceTeamCreatePage")
);
const WorkspaceTeamListPage = lazy(
  () => import("../pages/workspace/WorkspaceTeamListPage")
);
const WorkspaceUserCreatePage = lazy(
  () => import("../pages/workspace/WorkspaceUserCreatePage")
);
const WorkspaceUserUpdatePage = lazy(
  () => import("../pages/workspace/WorkspaceUserUpdatePage")
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function forbidSignin<A extends RouteProps<any>>(Page: FunctionComponent<A>) {
  return function Wrapper(props: A) {
    return (
      <ForbidSignin go={props.query.go}>
        <Page {...props} />
      </ForbidSignin>
    );
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function requireSignin<A extends RouteProps<any>>(Page: FunctionComponent<A>) {
  return function Wrapper(props: A) {
    return (
      <RequireSignin>
        <Page {...props} />
      </RequireSignin>
    );
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function passRoute<A extends RouteProps<any>>(Page: FunctionComponent<A>) {
  return function Wrapper(props: A) {
    return (
      <CommandBarProvider>
        <Page {...props} />
      </CommandBarProvider>
    );
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function embedRoute<A extends RouteProps<any>>(Page: FunctionComponent<A>) {
  return function Wrapper(props: A) {
    return <Page {...props} />;
  };
}

interface RouteWithWorkspace {
  path: DerivePathProps<[typeof path.workspaceRef]>;
  query: DeriveOutgoingQueryProps<AnyQueryParams>;
}

function workspaceRoute<A extends RouteWithWorkspace>(
  Page: FunctionComponent<A>,
  showFooter: boolean = true
) {
  return function Wrapper(props: A): ReactElement {
    const {
      path: { workspaceRef },
    } = props;

    const apiParams = useApiParams();
    const accessLevel = useSuspenseQueryData(
      queries.auth.v2.optReadWorkspaceAccess(apiParams, workspaceRef)
    );

    switch (accessLevel?.type) {
      case "WorkspaceAccessUnapproved":
      case "WorkspaceAccessDenied":
        return <Redirect route={routes.workspace.holding} {...props} />;
      case "WorkspaceAccessGranted":
      case undefined: // The user isn't authenticated yet. Let the underlying route handle it.
      case null:
        return (
          <RequireSignin>
            <CommandBarProvider>
              <PageLayout workspaceRef={workspaceRef} showFooter={showFooter}>
                <Page {...props} />
              </PageLayout>
            </CommandBarProvider>
          </RequireSignin>
        );
      default:
        return checkExhausted(accessLevel);
    }
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function plainRoute<A extends RouteProps<any>>(
  Page: FunctionComponent<A>,
  showSwitcher: boolean = true,
  showFooter: boolean = true
) {
  return function Wrapper(props: A): ReactElement {
    return (
      <RequireSignin>
        <PageLayout
          workspaceRef={null}
          showSwitcher={showSwitcher}
          showFooter={showFooter}
        >
          <CommandBarProvider>
            <Page {...props} />
          </CommandBarProvider>
        </PageLayout>
      </RequireSignin>
    );
  };
}

 
function workspaceRedirectRoute(
  url: (ref: WorkspaceRef) => string,
  permissionCheck: (id: WorkspaceId) => PermissionCheck
) {
  return function Wrapper(): ReactElement {
    return (
      <RequireSignin>
        <PageLayout workspaceRef={null} showSwitcher={false} showFooter={true}>
          <CommandBarProvider>
            <ShortWorkspacePage url={url} permissionCheck={permissionCheck} />
          </CommandBarProvider>
        </PageLayout>
      </RequireSignin>
    );
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function adminRoute<A extends RouteProps<any>>(
  Page: FunctionComponent<A>,
  check: PermissionCheck = checks.auth.globalAdmin
) {
  return function Wrapper(props: A): ReactElement {
    return (
      <RequireSignin>
        <RequirePermission
          check={check}
          fallback={<Navigate to={routes.home.url([])} />}
        >
          <CommandBarProvider>
            <PageLayout admin={true} workspaceRef={null}>
              <Page {...props} />
            </PageLayout>
          </CommandBarProvider>
        </RequirePermission>
      </RequireSignin>
    );
  };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function frontPluginRoute<A extends RouteProps<any>>(
  Page: FunctionComponent<A>
) {
  return function Wrapper(props: A): ReactElement {
    return (
      <RequireSignin>
        <Page {...props} />
      </RequireSignin>
    );
  };
}

 
function redirect<
  P extends AnyPathPart[],
  Q extends AnyQueryParams,
  A extends Omit<RedirectProps<P, Q>, "route">
>(to: Route<P, Q>) {
  return function Wrapper(props: A) {
    return <Redirect route={to} {...props} />;
  };
}

// prettier-ignore
const appRoutes: RouteObject[] = [
  routes.home.to(plainRoute(HomePage, false, true)),
  routes.login.to(redirect(routes.signin)),
  routes.signin.to(forbidSignin(SigninPage)),
  routes.signinWithCode.to(forbidSignin(SigninWithCodePage)),
  routes.logout.to(requireSignin(SignoutPage)),
  routes.signout.to(requireSignin(SignoutPage)),
  routes.password.reset.to(ResetPasswordPage),
  routes.password.forgot.to(forbidSignin(ForgotPasswordPage)),
  routes.signup.to(forbidSignin(SignupPage)),
  routes.account.to(plainRoute(AccountPage)),
  routes.identity.change.to(requireSignin(ChangeIdentityPage)),
  routes.identity.restore.to(requireSignin(RestoreIdentityPage)),
  routes.invitation.accept.to(passRoute(InvitationAcceptPage)),
  routes.embed.map.to(embedRoute(EmbedMapPage)),
  routes.embed.attachment.view.to(embedRoute(EmbedAttachmentPage)),
  routes.embed.attachment.folder.to(embedRoute(EmbedAttachmentFolderPage)),
  routes.qualification.register.to(embedRoute(QualificationRegisterPage)),
  routes.qualification.certificate.to(embedRoute(QualificationCertificatePage)),
  routes.attachment.view.to(requireSignin(AttachmentPage)),
  routes.attachment.folder.to(requireSignin(AttachmentFolderPage)),
  routes.short.survey.to(requireSignin(ShortSurveyUrlPage)),
  routes.short.map.to(requireSignin(ShortMapUrlPage)),
  routes.short.invitation.to(requireSignin(ShortInvitationPage)),
  routes.short.invitationCode.view.to(requireSignin(ShortInvitationCodePage)),
  routes.short.invitationCode.signup.approve.to(
    requireSignin(InvitationCodeSignupApprovePage)
  ),
  routes.short.invitationCode.signup.reject.to(
    requireSignin(InvitationCodeSignupRejectPage)
  ),
  routes.short.project.to(requireSignin(ShortProjectUrlPage)),
  routes.short.team.to(requireSignin(ShortTeamUrlPage)),
  routes.short.workspace.to(requireSignin(ShortWorkspaceUrlPage)),
  routes.short.settings.to(
    workspaceRedirectRoute(
      ref => routes.workspace.settings.url([ref]),
      checks.workspace.admin
    )
  ),
  routes.short.billing.to(
    workspaceRedirectRoute(
      ref => routes.workspace.billing.settings.url([ref]),
      checks.billing.workspace
    )
  ),
  routes.short.members.to(
    workspaceRedirectRoute(
      ref => routes.workspace.member.list.url([ref]),
      checks.workspace.admin
    )
  ),
  routes.dev.mapboxViewer.to(MapboxStyleViewerPage),
  routes.dev.frontPlugin.to(frontPluginRoute(FrontPluginPage)),
  routes.workspace.home.to(workspaceRoute(WorkspaceHomePage)),
  routes.workspace.holding.to(plainRoute(WorkspaceHoldingPage, false)),
  routes.workspace.settings.to(workspaceRoute(WorkspaceSettingsPage)),
  routes.workspace.billing.settings.to(
    workspaceRoute(WorkspaceBillingSettingsPage)
  ),
  routes.workspace.billing.dashboard.to(
    workspaceRoute(WorkspaceBillingDashboardPage)
  ),
  routes.workspace.billing.portal.to(
    workspaceRoute(WorkspaceBillingPortalPage)
  ),
  routes.workspace.project.list.to(workspaceRoute(WorkspaceProjectListPage)),
  routes.workspace.project.create.to(
    workspaceRoute(WorkspaceProjectCreatePage)
  ),
  routes.workspace.project.home.to(workspaceRoute(ProjectHomePage)),
  routes.workspace.project.settings.to(workspaceRoute(ProjectSettingsPage)),
  routes.workspace.project.integrations.list.to(
    workspaceRoute(ProjectIntegrationsPage)
  ),
  routes.workspace.project.integrations.arcgis.list.to(
    workspaceRoute(ArcgisIntegrationListPage)
  ),
  routes.workspace.project.integrations.arcgis.create.to(
    workspaceRoute(ArcgisIntegrationCreatePage)
  ),
  routes.workspace.project.integrations.arcgis.update.to(
    workspaceRoute(ArcgisIntegrationUpdatePage)
  ),
  routes.workspace.project.integrations.arcgis.authorize.to(
    workspaceRoute(ArcgisIntegrationAuthorizePage)
  ),
  routes.workspace.project.teams.to(workspaceRoute(ProjectTeamListPage)),
  routes.workspace.project.transfer.survey.to(
    workspaceRoute(ProjectSurveyTransferPage)
  ),
  routes.workspace.project.transfer.project.to(
    workspaceRoute(ProjectTransferPage)
  ),
  routes.workspace.project.member.list.to(
    workspaceRoute(ProjectMemberListPage)
  ),
  routes.workspace.project.member.update.to(
    workspaceRoute(ProjectUserUpdatePage)
  ),
  routes.workspace.project.invitation.list.to(
    workspaceRoute(ProjectInvitationListPage)
  ),
  routes.workspace.project.invitation.view.to(
    workspaceRoute(ProjectInvitationViewPage)
  ),
  routes.workspace.project.invitation.code.list.to(
    workspaceRoute(ProjectInvitationCodeListPage)
  ),
  routes.workspace.project.invitation.code.view.to(
    workspaceRoute(ProjectInvitationCodeViewPage)
  ),
  routes.workspace.project.survey.list.to(
    workspaceRoute(ProjectSurveyListPage)
  ),
  routes.workspace.project.survey.create.to(
    workspaceRoute(ProjectSurveyCreatePage)
  ),
  routes.workspace.project.survey.view.to(
    workspaceRoute(ProjectSurveyUpdatePage)
  ),
  routes.workspace.project.map.to(workspaceRoute(ProjectMapPage, false)),
  routes.workspace.team.list.to(workspaceRoute(WorkspaceTeamListPage)),
  routes.workspace.team.create.to(workspaceRoute(WorkspaceTeamCreatePage)),
  routes.workspace.team.home.to(workspaceRoute(TeamHomePage)),
  routes.workspace.team.settings.to(workspaceRoute(TeamSettingsPage)),
  routes.workspace.team.member.list.to(workspaceRoute(TeamMemberListPage)),
  routes.workspace.team.member.update.to(workspaceRoute(TeamUserPage)),
  routes.workspace.team.invitation.list.to(
    workspaceRoute(TeamInvitationListPage)
  ),
  routes.workspace.team.invitation.view.to(
    workspaceRoute(TeamInvitationViewPage)
  ),
  routes.workspace.team.invitation.code.list.to(
    workspaceRoute(TeamInvitationCodeListPage)
  ),
  routes.workspace.team.invitation.code.view.to(
    workspaceRoute(TeamInvitationCodeViewPage)
  ),
  routes.workspace.member.list.to(workspaceRoute(WorkspaceMemberListPage)),
  routes.workspace.member.create.to(workspaceRoute(WorkspaceUserCreatePage)),
  routes.workspace.member.update.to(workspaceRoute(WorkspaceUserUpdatePage)),
  routes.workspace.invitation.create.to(
    workspaceRoute(WorkspaceInvitationCreatePage)
  ),
  routes.workspace.invitation.view.to(
    workspaceRoute(WorkspaceInvitationViewPage)
  ),
  routes.workspace.invitation.list.to(
    workspaceRoute(WorkspaceInvitationListPage)
  ),
  routes.workspace.invitation.code.create.to(
    workspaceRoute(WorkspaceInvitationCodeCreatePage)
  ),
  routes.workspace.invitation.code.list.to(
    workspaceRoute(WorkspaceInvitationCodeListPage)
  ),
  routes.workspace.invitation.code.view.to(
    workspaceRoute(WorkspaceInvitationCodeViewPage)
  ),
  routes.workspace.catchAll.to(redirect(routes.workspace.home)),
  routes.admin.home.to(adminRoute(AdminHomePage)),
  routes.admin.workspace.list.to(adminRoute(AdminWorkspaceListPage)),
  routes.admin.workspace.create.to(adminRoute(AdminWorkspaceCreatePage)),
  routes.admin.billing.list.to(
    adminRoute(AdminBillingListPage, checks.billing.global)
  ),
  routes.admin.member.list.to(adminRoute(AdminMemberListPage)),
  routes.admin.member.create.to(adminRoute(AdminUserCreatePage)),
  routes.admin.member.view.to(adminRoute(AdminUserUpdatePage)),
  routes.admin.invitation.list.to(adminRoute(AdminInvitationListPage)),
  routes.admin.invitation.create.to(adminRoute(AdminInvitationCreatePage)),
  routes.admin.invitation.view.to(adminRoute(AdminInvitationViewPage)),
  routes.admin.invitation.code.list.to(adminRoute(AdminInvitationCodeListPage)),
  routes.admin.invitation.code.create.to(
    adminRoute(AdminInvitationCodeCreatePage)
  ),
  routes.admin.invitation.code.view.to(adminRoute(AdminInvitationCodeViewPage)),
  routes.admin.transfer.user.to(adminRoute(AdminUserTransferPage)),
  routes.admin.rcaSetup.to(adminRoute(AdminRcaSetupPage)),
  routes.admin.catchAll.to(redirect(routes.admin.home)),
  routes.catchAll.to(redirect(routes.home)),
];

export default function AppRoutes(): ReactElement | null {
  const routes = useRoutes(appRoutes);
  return <div className="AppRoutes">{routes}</div>;
}
