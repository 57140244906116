import { endpoints } from "@cartographerio/client";
import { IO } from "@cartographerio/io";
import {
  ApiParams,
  ProjectTransferRequest,
  ProjectTransferResult,
  SurveyTransferRequest,
  SurveyTransferResult,
  UserTransferRequest,
  UserTransferResult,
} from "@cartographerio/types";
import { QueryClient } from "@tanstack/react-query";

export function survey(
  queryClient: QueryClient,
  apiParams: ApiParams,
  request: SurveyTransferRequest
): IO<SurveyTransferResult> {
  return endpoints.transfer.v2
    .survey(apiParams, request)
    .tap(_ => queryClient.invalidateQueries(["survey"]));
}

export function user(
  queryClient: QueryClient,
  apiParams: ApiParams,
  request: UserTransferRequest
): IO<UserTransferResult> {
  return endpoints.transfer.v2
    .user(apiParams, request)
    .tap(_ => queryClient.invalidateQueries(["user"]))
    .tap(_ => queryClient.invalidateQueries(["survey"]));
}

export function project(
  queryClient: QueryClient,
  apiParams: ApiParams,
  request: ProjectTransferRequest
): IO<ProjectTransferResult> {
  return endpoints.transfer.v2
    .project(apiParams, request)
    .tap(_ => queryClient.invalidateQueries(["project"]))
    .tap(_ => queryClient.invalidateQueries(["teams"]))
    .tap(_ => {
      if (request.transferMembers) {
        queryClient.invalidateQueries(["user"]);
      }
    });
}
