import { endpoints } from "@cartographerio/client";
import { IO } from "@cartographerio/io";
import {
  ApiParams,
  Subscription,
  SubscriptionResponse,
  SubscriptionUpdate,
  Timestamp,
  WorkspaceRef,
  isSubscriptionResponseWaitForUpdate,
  timestampGt,
  timestampToDate,
} from "@cartographerio/types";
import { QueryClient } from "@tanstack/react-query";

import { STRIPE_SUBSCRIPTION_UPDATE_MAX_TIMEOUT } from "../../../config";
import { UseQueryOpts } from "../../base";

export type BillingSubscriptionKey =
  | ["billing", "workspace", "subscription"]
  | ["billing", "workspace", "subscription", "v1", "readOrFail", WorkspaceRef]
  | ["billing", "workspace", "subscription", "v1", "readOrNull", WorkspaceRef];

export function readOrFail(
  apiParams: ApiParams,
  workspaceRef: WorkspaceRef
): UseQueryOpts<Subscription, BillingSubscriptionKey> {
  return {
    queryKey: [
      "billing",
      "workspace",
      "subscription",
      "v1",
      "readOrFail",
      workspaceRef,
    ],
    queryFn: () =>
      endpoints.billing.subscription.v1
        .readOrFail(apiParams, workspaceRef)
        .unsafeRun(),
  };
}

export function readOrNull(
  apiParams: ApiParams,
  workspaceRef: WorkspaceRef
): UseQueryOpts<Subscription | null, BillingSubscriptionKey> {
  return {
    queryKey: [
      "billing",
      "workspace",
      "subscription",
      "v1",
      "readOrNull",
      workspaceRef,
    ],
    queryFn: () =>
      endpoints.billing.subscription.v1
        .readOption(apiParams, workspaceRef)
        .map(opt => opt.getOrNull())
        .unsafeRun(),
  };
}

function pollForUpdate(
  apiParams: ApiParams,
  workspaceRef: WorkspaceRef,
  timestamp: Timestamp,
  pollInterval: number = 500
): IO<void> {
  return IO.callback((res, rej) => {
    function poll() {
      endpoints.billing.subscription.v1
        .readOption(apiParams, workspaceRef)
        .map(sub => sub.getOrNull())
        .tap(sub => {
          if (sub != null && timestampGt(sub.updated, timestamp)) {
            res();
          } else if (
            Date.now() >
            timestampToDate(timestamp).getTime() +
              STRIPE_SUBSCRIPTION_UPDATE_MAX_TIMEOUT
          ) {
            rej(new Error("Timed out waiting for subscription to update"));
          } else {
            setTimeout(poll, pollInterval);
          }
        })
        .unsafeRun();
    }

    poll();
  });
}

export function save(
  queryClient: QueryClient,
  apiParams: ApiParams,
  workspaceRef: WorkspaceRef,
  update: SubscriptionUpdate
): IO<SubscriptionResponse> {
  return endpoints.billing.subscription.v1
    .save(apiParams, workspaceRef, update)
    .tap(resp =>
      isSubscriptionResponseWaitForUpdate(resp)
        ? pollForUpdate(apiParams, workspaceRef, resp.timestamp)
        : null
    )
    .cleanup(() => queryClient.invalidateQueries(["billing", "workspace"]));
}
