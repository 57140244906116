import { endpoints } from "@cartographerio/client";
import {
  ApiParams,
  WorkspaceId,
  WorkspaceLimits,
  WorkspaceLimitsCheckResult,
  WorkspaceRef,
} from "@cartographerio/types";
import { QueryClient } from "@tanstack/react-query";

import { UseQueryOpts } from "../../base";

export type WorkspaceLimitsKey =
  | ["workspace", "limits"]
  | ["workspace", "limits", "v1", "read", WorkspaceRef]
  | ["workspace", "limits", "v1", "checkUsers", WorkspaceRef]
  | ["workspace", "limits", "v1", "checkAllUsers"];

export function readOrFail(
  apiParams: ApiParams,
  workspaceRef: WorkspaceRef
): UseQueryOpts<WorkspaceLimits, WorkspaceLimitsKey> {
  return {
    queryKey: ["workspace", "limits", "v1", "read", workspaceRef],
    queryFn: () =>
      endpoints.workspace.limits.v1
        .readOrFail(apiParams, workspaceRef)
        .unsafeRun(),
  };
}

export function save(
  queryClient: QueryClient,
  apiParams: ApiParams,
  workspaceRef: WorkspaceRef,
  limits: WorkspaceLimits
) {
  return endpoints.workspace.limits.v1
    .save(apiParams, workspaceRef, limits)
    .tap(_ => queryClient.invalidateQueries(["workspace", "limits"]));
}

export function checkUsers(
  apiParams: ApiParams,
  workspaceRef: WorkspaceRef
): UseQueryOpts<WorkspaceLimitsCheckResult, WorkspaceLimitsKey> {
  return {
    queryKey: ["workspace", "limits", "v1", "checkUsers", workspaceRef],
    queryFn: () =>
      endpoints.workspace.limits.v1
        .checkUsers(apiParams, workspaceRef)
        .unsafeRun(),
  };
}

export function checkAllUsers(
  apiParams: ApiParams
): UseQueryOpts<WorkspaceId[], WorkspaceLimitsKey> {
  return {
    queryKey: ["workspace", "limits", "v1", "checkAllUsers"],
    queryFn: () =>
      endpoints.workspace.limits.v1.checkAllUsers(apiParams).unsafeRun(),
  };
}
