import { PermissionCheck } from "@cartographerio/types";
import { WorkspaceGraph } from "@cartographerio/workspace-graph";
import { useMemo } from "react";

import usePermissionCheckRunner from "./usePermissionCheckRunner";

export type PermissionCheckRunner = (check: PermissionCheck) => boolean;

export default function usePermissionCheck(
  check: PermissionCheck,
  graph?: WorkspaceGraph
): boolean {
  const runner = usePermissionCheckRunner(graph);
  return useMemo(() => runner(check), [check, runner]);
}
