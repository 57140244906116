import { SystemProps } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import {
  ReactElement,
  ReactNode,
  Suspense,
  useCallback,
  useState,
} from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";

import { isProductionMode } from "../../env";
import ErrorPlaceholder from "./ErrorPlaceholder";
import LoadingPlaceholder from "./LoadingPlaceholder";

interface SuspenseBoundaryProps extends SystemProps {
  children: ReactNode;
  showSignout?: boolean;
}

type ErrorHandler = (
  error: Error,
  info: { componentStack?: string | null }
) => void;

export default function SuspenseBoundary(
  props: SuspenseBoundaryProps
): ReactElement {
  const { children, showSignout, ...rest } = props;

  const [eventId, setEventId] = useState<string | undefined>(undefined);

  const handleError = useCallback<ErrorHandler>((error, { componentStack }) => {
    if (isProductionMode) {
      setEventId(
        Sentry.captureException(error, {
          contexts: { react: { componentStack } },
        })
      );
    }
  }, []);

  const fallbackRender = useCallback<(props: FallbackProps) => ReactElement>(
    props => (
      <ErrorPlaceholder
        maxW="60ch"
        mx="auto"
        px="4"
        showSignout={showSignout}
        sentryEventId={eventId}
        {...props}
      />
    ),
    [eventId, showSignout]
  );

  return (
    <ErrorBoundary fallbackRender={fallbackRender} onError={handleError}>
      <Suspense fallback={<LoadingPlaceholder {...rest} />}>
        {children}
      </Suspense>
    </ErrorBoundary>
  );
}
