import { CheckWorkspaceAccessResult } from "@cartographerio/types";
import {
  Modal,
  ModalContent,
  ModalOverlay,
  useBoolean,
} from "@chakra-ui/react";
import { useHotkey } from "@react-hook/hotkey";
import { ReactElement, ReactNode, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import queries from "../../queries";
import { emptyArray } from "../../util";
import { useApiParams } from "../contexts/auth";
import { useSuspenseSearchResults } from "../hooks/useSuspenseSearchResults";
import { routes } from "../routes";
import WorkspaceSelector from "./WorkspaceSelector";

export interface CommandBarProviderProps {
  children: ReactNode;
}

export default function CommandBarProvider(
  props: CommandBarProviderProps
): ReactElement {
  const { children } = props;

  const apiParams = useApiParams();
  const navigate = useNavigate();

  // If we're not logged in, don't bother trying to check workspace access (it'll just throw a missing auth exception):
  const access =
    useSuspenseSearchResults(
      queries.optional(apiParams.auth, () =>
        queries.auth.v2.listWorkspaceAccess(apiParams)
      )
    ) ?? emptyArray;

  const [isOpen, { on: onOpen, off: handleClose }] = useBoolean();

  const handleSelect = useCallback(
    (access: CheckWorkspaceAccessResult) => {
      navigate(routes.workspace.home.url([access.alias]));
      handleClose();
    },
    [handleClose, navigate]
  );

  useHotkey(
    window,
    ["mod", "k"],
    useCallback(
      evt => {
        evt.stopPropagation();
        evt.preventDefault();
        onOpen();
      },
      [onOpen]
    )
  );

  return (
    <>
      {children}
      <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent p="2">
          <WorkspaceSelector
            options={access}
            onSelect={handleSelect}
            onCancel={handleClose}
          />
        </ModalContent>
      </Modal>
    </>
  );
}
