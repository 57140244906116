import { isEqual } from "lodash-es";
import { FunctionComponent, ReactElement, useEffect, useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { useOptCredentials } from "../ui/contexts/auth";
import usePrevious from "../ui/hooks/usePrevious";
import useSavedQueryParams from "../ui/hooks/useSavedQueryParams";
import { AnyPathPart, AnyQueryParams } from "./base";
import {
  DeriveOutgoingQueryProps,
  DerivePathProps,
  DeriveProps,
  DeriveQueryUpdate,
} from "./derive";
import { Route } from "./Route";

export interface RouteAdapterProps<
  P extends AnyPathPart[],
  Q extends AnyQueryParams,
> {
  builder: Route<P, Q>;
  Component: FunctionComponent<DeriveProps<P, Q>>;
}

export function RouteAdapter<P extends AnyPathPart[], Q extends AnyQueryParams>(
  props: RouteAdapterProps<P, Q>
): ReactElement {
  const { builder, Component } = props;

  const _path = useParams();
  const [_query, _setQuery] = useSearchParams();
  const userId = useOptCredentials()?.identity.userId;

  const cacheVersion = builder.queryOptions.cache
    ? (builder.queryOptions.cacheVersion ?? "v1")
    : null;

  const setQuery = useSavedQueryParams(_setQuery, cacheVersion, userId ?? null);

  const path: DerivePathProps<P> | undefined = useMemo(
    () => builder.pathProps(_path),
    [builder, _path]
  );

  const query: DeriveOutgoingQueryProps<Q> | undefined = useMemo(
    () => builder.queryProps(_query),
    [builder, _query]
  );

  const updateQuery: DeriveQueryUpdate<Q> = useMemo(
    () => builder.queryUpdate(setQuery),
    [builder, setQuery]
  );

  const prevBuilder = usePrevious(builder);
  const prevPath = usePrevious(path);
  const prevQuery = usePrevious(query);

  useEffect(() => {
    if (
      !isEqual(builder, prevBuilder) ||
      !isEqual(path, prevPath) ||
      !isEqual(query, prevQuery)
    ) {
      console.info("ROUTE", builder.reactRouterPattern(), path, query);
    }
  }, [builder, path, prevBuilder, prevPath, prevQuery, query]);

  if (path == null || query == null) {
    throw new Error("Could not extract route props");
  } else {
    return <Component path={path} query={query} updateQuery={updateQuery} />;
  }
}
