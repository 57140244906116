import { checks } from "@cartographerio/permission";
import { MapSchema } from "@cartographerio/topo-map";
import { Project } from "@cartographerio/types";
import { sortBy } from "lodash-es";
import { useMemo } from "react";

import useMapInventory from "./useMapInventory";
import usePermissionCheckRunner from "./usePermissionCheckRunner";

export function useVisibleMaps(project: Project): MapSchema[] {
  const inventory = useMapInventory();
  const permissionCheckPasses = usePermissionCheckRunner();

  return useMemo(
    () =>
      permissionCheckPasses(checks.map.view(project))
        ? sortBy(
            inventory.maps().filter(map => project.mapIds.includes(map.mapId)),
            map => map.title
          )
        : [],
    [permissionCheckPasses, inventory, project]
  );
}
