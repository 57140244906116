import "./markdown.css";

import { Node, markdownAst } from "@cartographerio/markdown";
import { checkExhausted } from "@cartographerio/util";
import { Box, Image, SystemProps, chakra } from "@chakra-ui/react";
import { isEqual } from "lodash-es";
import { ReactElement, ReactNode, memo } from "react";

import Pre from "./Pre";

interface MarkdownProps extends SystemProps {
  text: string;
}

function Markdown({ text, ...rest }: MarkdownProps): ReactElement {
  return (
    <chakra.div className="markdown" {...rest}>
      <MarkdownInner node={markdownAst(text)} />
    </chakra.div>
  );
}

export default memo(Markdown, isEqual);

function children(nodes: Node[]): ReactNode {
  return nodes.map((child, index) => {
    return <MarkdownInner key={index} node={child} />;
  });
}

function isInline(node: Node) {
  switch (node.type) {
    case "BlockQuote":
      return false;
    case "Code":
      return true;
    case "CodeBlock":
      return false;
    case "CustomBlock":
      return false;
    case "CustomInline":
      return true;
    case "Document":
      return false;
    case "Emph":
      return true;
    case "Heading":
      return false;
    case "HtmlBlock":
      return false;
    case "HtmlInline":
      return true;
    case "Image":
      return false;
    case "Item":
      return false;
    case "LineBreak":
      return true;
    case "Link":
      return true;
    case "List":
      return false;
    case "Paragraph":
      return false;
    case "SoftBreak":
      return true;
    case "Strong":
      return true;
    case "Text":
      return true;
    case "ThematicBreak":
      return true;
    default:
      return checkExhausted(node);
  }
}

interface MarkdownInnerProps {
  node: Node;
}

function MarkdownInner({ node }: MarkdownInnerProps): ReactElement {
  if (typeof node === "string") {
    return <span>{node}</span>;
  } else {
    switch (node.type) {
      case "BlockQuote": {
        return <blockquote>{children(node.children)}</blockquote>;
      }
      case "Code": {
        return <code>{node.code}</code>;
      }
      case "CodeBlock": {
        return (
          <>
            {node.info && (
              <chakra.span
                display="block"
                w="full"
                my={2}
                textAlign="center"
                fontSize="sm"
              >
                {node.info}
              </chakra.span>
            )}
            <Pre text={node.code} />
          </>
        );
      }
      case "CustomBlock": {
        return <Box>{node.text}</Box>;
      }
      case "CustomInline": {
        return <span>{node.text}</span>;
      }
      case "Document": {
        return <>{children(node.children)}</>;
      }
      case "Emph": {
        return <em>{children(node.children)}</em>;
      }
      case "Heading": {
        switch (node.level) {
          case 1:
            return (
              <chakra.h1 fontSize="lg" fontWeight="semibold" mt="4">
                {children(node.children)}
              </chakra.h1>
            );
          case 2:
          default:
            return (
              <chakra.h2 fontSize="lg" mt="2">
                {children(node.children)}
              </chakra.h2>
            );
        }
      }

      case "HtmlBlock": {
        return (
          <div
            className="HtmlBlock"
            dangerouslySetInnerHTML={{ __html: node.html }}
          />
        );
      }
      case "HtmlInline": {
        return <>{node.html}</>;
      }
      case "Image": {
        return (
          // Image width is the same as in FieldView:
          <chakra.figure maxWidth="44ch" mx="auto" fontSize="md">
            <Image src={node.src} rounded="md" />

            <chakra.figcaption
              display="block"
              w="full"
              textAlign="center"
              fontSize="sm"
              mt="2"
            >
              {children(node.children)}
            </chakra.figcaption>
          </chakra.figure>
        );
      }
      case "Item": {
        return <chakra.li>{children(node.children)}</chakra.li>;
      }
      case "LineBreak": {
        return <br />;
      }
      case "Link": {
        return (
          <chakra.a
            textDecoration="underline"
            textColor="blue.500"
            rel="noreferrer"
            target="_blank"
            href={node.href}
          >
            {children(node.children)}
          </chakra.a>
        );
      }
      case "List": {
        if (node.listType === "bullet") {
          return <chakra.ul>{children(node.children)}</chakra.ul>;
        } else {
          return <chakra.ol>{children(node.children)}</chakra.ol>;
        }
      }
      case "Paragraph": {
        if (node.children.every(isInline)) {
          return <chakra.p>{children(node.children)}</chakra.p>;
        } else {
          return <>{children(node.children)}</>;
        }
      }
      case "SoftBreak": {
        return <> </>;
      }
      case "Strong": {
        return <strong>{children(node.children)}</strong>;
      }
      case "Text": {
        return <span dangerouslySetInnerHTML={{ __html: node.text }} />;
      }
      case "ThematicBreak": {
        return <hr />;
      }
      default: {
        return checkExhausted(node);
      }
    }
  }
}
