import * as Sentry from "@sentry/react";

import { isProductionMode } from "./env";
import { envvar } from "./util/envvar";

export const VITE_NAME = envvar("VITE_NAME");
export const VITE_VERSION = envvar("VITE_VERSION");
const VITE_SENTRY_DSN = envvar("VITE_SENTRY_DSN");

export function initSentry(): void {
  Sentry.init({
    release: [VITE_NAME, VITE_VERSION].join("@"),
    dsn: isProductionMode ? VITE_SENTRY_DSN : undefined,
    normalizeDepth: 50,
  });
}
